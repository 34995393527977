<script setup>
import Button from '@/components/button/Button.vue';
import Writer from '@/utils/Writer.vue';
import { useDark } from '@vueuse/core';
import social from '@/data/social.json';

const isDark = useDark();
</script>
<template>
  <section
    class="flex flex-col-reverse lg:flex-row justify-between items-center w-full mt-10 transition-colors duration-1000"
  >
    <article class="flex flex-col items-start justify-between gap-5">
      <header>
        <h1
          class="text-4xl sm:text-5xl font-bold text-black dark:text-white transition-colors duration-1000"
        >
          <span class="text-3xl">Hello,</span><br />
          I'm <span class="font-extrabold text-orange-500">Jeyther</span> <br />
          Developer of unique <br />
          experiences
        </h1>
      </header>

      <aside class="flex text-orange-500 font-semibold">
        <Writer
          :array="['github.com/', 'linkedin.com/in/', 'gitlab.com/']"
          :eraseSpeed="50"
          :typeSpeed="100"
          :caretColor="isDark ? 'white' : 'black'"
        />
        <p class="font-bold">jeyther</p>
      </aside>

      <aside>
        <ul class="flex gap-6">
          <a
            :href="item.link"
            target="_blank"
            v-for="item in social"
            :key="item"
          >
            <li
              class="rounded-full bg-slate-900 p-2 hover:scale-110 hover:bg-slate-800 transition-all duration-300"
            >
              <picture class="">
                <img
                  :src="'/images/icons/svg/' + item.icon + '.svg'"
                  :alt="item.icon + 'icon'"
                />
              </picture>
            </li>
          </a>
        </ul>
      </aside>

      <aside
        class="flex flex-col w-full sm:w-auto sm:flex-row place-self-center lg:place-self-auto gap-6"
      >
        <Button :label="'Download CV'" :type="'primary'" :download="true" />
        <Button
          :contactMe="true"
          :label="'Contact Me'"
          :type="'outlined'"
          :to="'https://api.whatsapp.com/send/?phone=926880954&text&type=phone_number&app_absent=0'"
        />
      </aside>
    </article>

    <picture class="relative flex items-center justify-center w-2/4">
      <img
        src="/images/hero/hero-image.png"
        alt="hero image"
        class="absolute w-10/12 z-10"
      />
      <img
        src="/images/hero/hero-image.png"
        alt="hero image"
        class="blur-lg w-11/12 animate-pulse"
      />
    </picture>
  </section>
</template>
<style scoped lang="scss"></style>
