<script setup>
import { ref } from 'vue';
import { useDark, useToggle } from '@vueuse/core';
const isDark = useDark();
const toggleDark = useToggle(isDark);

const dark = ref(isDark);
</script>
<template>
  <label class="switch flex">
    <input
      type="checkbox"
      v-model="dark"
      :true-value="false"
      :false-value="true"
    />
    <span class="slider"></span>
  </label>
</template>
<style scoped lang="scss">
.switch {
  --width-of-switch: 3rem;
  --height-of-switch: 2rem;
  /* size of sliding icon -- sun and moon */
  --size-of-icon: 1.4rem;
  /* it is like a inline-padding of switch */
  --slider-offset: 0.3rem;
  position: relative;
  width: var(--width-of-switch);
  height: var(--height-of-switch);
  cursor: pointer;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  // cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(48, 49, 54, 0.6);
  transition: 0.4s;
  border-radius: 30px;
}
.slider:before {
  position: absolute;
  content: '';
  height: var(--size-of-icon, 1.4em);
  width: var(--size-of-icon, 1.4em);
  border-radius: 20px;
  left: var(--slider-offset, 0.3em);
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(40deg, #f16f30, #f49b5a 70%);
  transition: 0.4s;
}
input:checked + .slider {
  background-color: rgb(244, 244, 245, 0.3);
}
input:checked + .slider:before {
  left: calc(100% - (var(--size-of-icon, 1.4em) + var(--slider-offset, 0.3em)));
  background: rgb(244, 244, 245, 0);
  /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
  box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #3d5ba5;
}
</style>
