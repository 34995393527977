<script setup>
import DarkSwitch from '@/components/navbar/DarkSwitch.vue';
import { computed } from 'vue';
import MobileMenuSwitch from './navbar/MobileMenuSwitch.vue';
import store from '@/store';

const openNav = computed(() => store.state.openNav);
</script>
<template>
  <nav
    class="sticky top-4 mt-3 flex w-full z-50 backdrop-filter backdrop-blur-lg bg-opacity-10 rounded-2xl bg-black dark:bg-white dark:bg-opacity-5 transition-colors duration-1000"
  >
    <header
      class="flex flex-col lg:flex-row gap-4  justify-between w-full px-6 py-4 items-center overflow-hidden h-16 transition-all duration-700 "
      :class="{ 'items-start h-72': openNav }"
    >
      <!-- LOGO & TOGGLE -->
      <div
        class="flex justify-between w-full lg:w-auto lg:justify-center items-center"
      >
        <!-- TITLE -->
        <div class="flex items-center gap-1">
          <picture class="flex w-4">
            <img src="/images/icons/svg/logo-dark.svg" alt="Jeyther logo" />
          </picture>

          <span class="text-orange-500 font-medium">eyther</span>
        </div>

        <MobileMenuSwitch />
      </div>
      <!-- MENU -->
      <ul
        class="flex flex-col lg:flex-row items-center gap-6 text-black dark:text-white transition-colors duration-1000"
      >
        <li class="Navbar-item relative">
          <a href="#about" class="Navbar-link isActive">Home</a>
        </li>
        <li class="Navbar-item relative">
          <a href="#about" class="Navbar-link">About me</a>
        </li>

        <li class="Navbar-item relative">
          <a href="#projects" class="Navbar-link">Projects</a>
        </li>

        <li class="Navbar-item relative">
          <a href="#skills" class="Navbar-link">Skills</a>
        </li>
      </ul>

      <DarkSwitch />
    </header>
  </nav>
</template>

<style scoped lang="scss">
.Navbar-link::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 100%;
  mix-blend-mode: darken;
  transition: 0.4s ease-in-out;
  overflow: hidden;
  left: -100%;
  bottom: 0;
}

.Navbar-link.isActive::after {
  background: #f16f30;
  left: 0;
}

.Navbar-item:hover .Navbar-link::after {
  background: #f16f30;
  left: 0;
}
</style>
