import { createStore } from 'vuex';

export default createStore({
  state: {
    openNav: false,
  },
  actions: {
    toggleNav({ commit }) {
      commit('toggleNav');
    },
  },
  mutations: {
    toggleNav(state) {
      state.openNav = !state.openNav;
    },
  },
  getters: {},
  modules: {},
});
