<script setup></script>
<template>
  <section
    class="w-full flex flex-col items-center justify-center gap-16 "
  >
    <h3 class="text-center font-mono text-xl text-black dark:text-white transition-colors duration-1000">
      "The Journey is just beginning 🚀"
    </h3>
    <div class="w-full flex flex-col items-center justify-center gap-10">
      <h2 class="text-3xl font-bold text-orange-500">Who I Am</h2>
      <p class="md:w-3/6 text-center text-black dark:text-white transition-colors duration-1000">
        I’m a Front End Web Developer 👨🏻‍💻 with more than one year of experience
        in many technologies and frameworks. I’m already learning Back End
        technologies too. I like to discover new technologies, new ways to solve
        a problem and always seek to diversify my knowledge to be prepared for
        future challenges.
      </p>
    </div>
  </section>
</template>
<style scoped lang="scss"></style>
