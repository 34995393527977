<script setup>
import { ref, onMounted, computed } from 'vue';

const props = defineProps({
  array: {
    type: Array,
    required: true,
  },
  eraseSpeed: {
    type: Number,
    default: 100,
  },
  typeSpeed: {
    type: Number,
    default: 200,
  },
  delay: {
    type: Number,
    default: 2000,
  },
  intervals: {
    type: Number,
    default: 500,
  },
  start: {
    type: Number,
    default: 0,
  },
  caret: {
    type: String,
    default: 'cursor',
  },
  caretColor: {
    type: String,
    default: 'black',
  },
  textColor: {
    type: String,
    default: 'none',
  },
  iterations: {
    type: Number,
    default: 0,
  },
});

const typeValue = ref('');
const count = ref(0);
const typeStatus = ref(false);
const arrayIndex = ref(0);
const charIndex = ref(0);

const caretStyle = computed(() => {
  return {
    '--bg-caret-color': props.caretColor,
  };
});
const textStyle = computed(() => {
  return {
    '--bg-text-color': props.textColor,
  };
});

const typewriter = () => {
  let loop = 0;
  if (charIndex.value < props.array[arrayIndex.value].length) {
    if (!typeStatus.value) {
      typeStatus.value = true;
    }

    typeValue.value += props.array[arrayIndex.value].charAt(charIndex.value);
    charIndex.value += 1;
    setTimeout(typewriter, props.typeSpeed);
  } else {
    count.value += 1;

    if (count.value === props.array.length) {
      loop += 1;
      if (loop === props.iterations) {
        return (typeStatus.value = false);
      }
    }

    typeStatus.value = false;

    setTimeout(eraser, props.delay);
  }
};
const eraser = () => {
  if (charIndex.value > 0) {
    if (!typeStatus.value) {
      typeStatus.value = true;
    }
    typeValue.value = props.array[arrayIndex.value].substring(
      0,
      charIndex.value - 1
    );
    charIndex.value -= 1;
    setTimeout(eraser, props.eraseSpeed);
  } else {
    typeStatus.value = false;
    arrayIndex.value += 1;
    if (arrayIndex.value >= props.array.length) arrayIndex.value = 0;
    setTimeout(typewriter, props.typeSpeed + props.intervals);
  }
};

onMounted(() => {
  setTimeout(typewriter, props.start);
});
</script>
<template>
  <div class="is-typed">
    <slot />
    <span class="typed" :style="textStyle">{{ typeValue }}</span>
    <span :class="[caret, { typing: typeStatus }]" :style="caretStyle"
      >&nbsp;</span
    >
  </div>
</template>
<style scoped>
.is-typed {
  /* font-family: 'Monaco'; */
}
.is-typed span.typed {
  color: var(--bg-text-color);
}
.is-typed span.cursor {
  display: inline-block;
  width: 2px;
  background-color: var(--bg-caret-color);
  animation: blink 1s infinite;
}
.is-typed span.underscore {
  display: inline-flex;
  width: 10px;
  height: 1px;
  align-items: flex-end;
  background-color: var(--bg-caret-color);
  animation: blink 1s infinite;
}
.is-typed span.cursor.typing {
  animation: none;
}
@keyframes blink {
  49% {
    background-color: var(--bg-caret-color);
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
</style>
